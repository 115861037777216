import { DeliveryError } from '@kontent-ai/delivery-sdk';
import { isBlank } from './isBlank';

const getConditions = (field) => {
    return !!field?.elements?.visibility_conditions?.linkedItems?.length
        ? field?.elements?.visibility_conditions?.linkedItems?.map((item) => {
              const type = item.system.type;
              switch (type) {
                  case 'field_condition':
                      return {
                          field: !!item?.elements?.form_field?.linkedItems
                              ?.length
                              ? item?.elements?.form_field?.linkedItems[0]
                                    ?.elements?.name?.value
                              : null,
                          condition:
                              item?.elements?.condition?.value[0]?.codename ||
                              'is_equal_to',
                          value: item?.elements?.value?.value,
                          type,
                      };
                  case 'field_condition_group':
                      return {
                          operator:
                              item?.elements?.operator?.value[0]?.codename ||
                              'or',
                          conditions: getConditions(item),
                          type,
                      };
                  default:
                      return null;
              }
          })
        : [];
};

export const getFormElementFields = (field) => {
    try {
        if (!field) return null;
        const type =
            field?.elements?.type?.value[0]?.codename || field?.system?.type;

        const id = field?.system?.id;
        const width = field?.elements?.width?.value[0]?.codename;
        const placeholder = field?.elements?.placeholder?.value || null;
        const default_value = field?.elements?.default_value?.value || null;
        const options = !!field?.elements?.options?.value?.length
            ? field?.elements?.options?.value?.split('\n').map((item) => {
                  const radio = item.split('|');
                  return {
                      label: radio[0],
                      value: radio[1],
                  };
              })
            : null;
        const visibility_conditions = getConditions(field);
        const required_conditions = !!field?.elements?.required_conditions
            ?.linkedItems?.length
            ? field?.elements?.required_conditions?.linkedItems?.map((item) => {
                  return {
                      field: !!item?.elements?.form_field?.linkedItems?.length
                          ? item?.elements?.form_field?.linkedItems[0]?.elements
                                ?.name?.value
                          : null,
                      condition:
                          item?.elements?.condition?.value[0]?.codename ||
                          'is_equal_to',
                      value: item?.elements?.value?.value,
                  };
              })
            : false;
        const base = {
            label: field?.elements?.label?.value || null,
            name: field?.elements?.name?.value || null,
            helper: field?.elements?.helper?.value || null,
            disabled: !!field?.elements?.disabled?.value?.length || false,
            visibility_conditions,
            required_conditions,
            reset: !!field?.elements?.reset?.linkedItems?.length
                ? field?.elements?.reset?.linkedItems[0]?.elements?.name?.value
                : null,
            answer: field?.elements?.answer?.value || null,
            correct: isBlank(field?.elements?.correct),
            incorrect: isBlank(field?.elements?.incorrect),
            validation: {
                required: field?.elements?.required?.value || false,
                pattern: !!field?.elements?.pattern_value?.value.length
                    ? {
                          value: field?.elements?.pattern_value?.value || null,
                          message:
                              field?.elements?.pattern_message?.value || null,
                      }
                    : null,
            },
            id,
            type,
        };
        switch (type) {
            case 'text':
                return {
                    ...base,
                    placeholder,
                    default_value,
                    width,
                };
            case 'date':
                return {
                    ...base,
                    width,
                };
            case 'time':
                return {
                    ...base,
                    width,
                };
            case 'tel':
                return {
                    ...base,
                    width,
                };
            case 'email':
                return {
                    ...base,
                    placeholder,
                    width,
                };
            case 'select':
                return {
                    ...base,
                    placeholder,
                    isMulti:
                        !!field?.elements?.multi_select?.value?.length || false,
                    default_value,
                    options,
                    width,
                };
            case 'password':
                return {
                    ...base,
                    width,
                };
            case 'radio':
                return {
                    ...base,
                    default_value,
                    options,
                };
            case 'checkbox':
                return {
                    ...base,
                    split:
                        !!field?.elements?.split_checkbox?.value?.length ||
                        false,
                    parse_rich_text:
                        !!field?.elements?.parse_rich_text?.value?.length ||
                        false,
                    options,
                };
            case 'text_area':
                return base;
            case 'form_field_group':
                return {
                    fields: field?.elements?.form_fields?.linkedItems?.map(
                        (field) => {
                            return getFormElementFields(field);
                        }
                    ),
                    visibility_conditions,
                    id,
                    type,
                };
            case 'form_step':
                return {
                    title: isBlank(field?.elements?.title) || null,
                    fields: field?.elements?.form_fields?.linkedItems?.map(
                        (field) => {
                            return getFormElementFields(field);
                        }
                    ),
                    back: field?.elements?.back?.value,
                    continue: field?.elements?.continue?.value,
                    id,
                    type,
                };
            case 'rich_text':
                return {
                    content: isBlank(field?.elements?.content) || null,
                    element:
                        field?.elements?.element?.value[0]?.codename ||
                        'paragraph',
                    visibility_conditions,
                    id,
                    type,
                };
            case 'horizontal_rule':
                return {
                    id,
                    type,
                };
            case 'hidden':
                return {
                    name: field?.elements?.name?.value || null,
                    default_value,
                    id,
                    type,
                };
            default:
                return null;
        }
    } catch (error) {
        if (error instanceof DeliveryError) {
            console.error(error.message, error.errorCode);
            return null;
        } else {
            console.error(error);
            return null;
        }
    }
};

export const getVisibilityConditions = ({ data, visibility_conditions }) => {
    try {
        if (!visibility_conditions?.length) {
            return true;
        }

        const condition = visibility_conditions[0];

        const evaluateCondition = (item) => {
            const { field, condition, value } = item;
            switch (condition) {
                case 'is_equal_to':
                    return data[field] === value;
                case 'is_not_equal_to':
                    return data[field] !== value;
                case 'includes':
                    return data[field]?.includes(value);
                default:
                    return false;
            }
        };

        const evaluateConditionGroup = (group) => {
            switch (group.operator) {
                case 'and':
                    return group.conditions.every((item) => {
                        switch (item.type) {
                            case 'field_condition':
                                return evaluateCondition(item);
                            case 'field_condition_group':
                                return evaluateConditionGroup(item);
                            default:
                                return false;
                        }
                    });
                case 'or':
                    return group.conditions.some((item) => {
                        switch (item.type) {
                            case 'field_condition':
                                return evaluateCondition(item);
                            case 'field_condition_group':
                                return evaluateConditionGroup(item);
                            default:
                                return false;
                        }
                    });
                default:
                    return false;
            }
        };

        switch (condition.type) {
            case 'field_condition':
                return evaluateCondition(condition);
            case 'field_condition_group':
                return evaluateConditionGroup(condition);
            default:
                return false;
        }
    } catch (error) {
        throw new Error(
            'Error evaluating visibility conditions: ' + error.message
        );
    }
};

export const getRequiredConditions = ({
    data,
    required_conditions,
    required,
}) => {
    try {
        // Check that there is required text and no conditions
        if (required && !required_conditions) {
            return required;
        }
        // Check that there are any required conditions
        if (required && required_conditions) {
            const passed_conditions = required_conditions.some((item) => {
                const { field, condition, value } = item;
                if (condition === 'is_equal_to') {
                    return data[field] === value;
                }
            });
            if (passed_conditions) {
                return required;
            }
        }
    } catch (error) {
        console.log(error);
    }
};
