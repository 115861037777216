/**
 * Form Block
 * A Form block is a templated example of a form. Use this to show the layout variant of a particular form, but keep in mind that this will not be the final form itself, as form fields can be customized for each instance of a form.
 */

import Form1a from './variants/Form1a'; // sidebar title top
import Form1b from './variants/Form1b'; // sidebar title right

import Form2a from './variants/Form2a'; // no sidebar
import Form2b from './variants/Form2b'; // no sidebar, short introduction
import Form2c from './variants/Form2c';

import Form3a from './variants/Form3a';

import Form4a from './variants/Form4a';

import FormIframe from './variants/FormIframe';
import FormMarketoIframe from './variants/FormMarketoIframe';

const prefix = 'form';

const Form = ({ ...block }) => {
    switch (block.variant) {
        case `${prefix}_1a`:
            return <Form1a {...block} />;
        case `${prefix}_1b`:
            return <Form1b {...block} />;
        case `${prefix}_2a`:
            return <Form2a {...block} />;
        case `${prefix}_2b`:
            return <Form2b {...block} />;
        case `${prefix}_2c`:
            return <Form2c {...block} />;
        case `${prefix}_3a`:
            return <Form3a {...block} />;
        case `${prefix}_4a`:
            return <Form4a {...block} />;
        case `${prefix}_iframe`:
            return <FormIframe {...block} />;
        case `${prefix}_marketo_iframe`:
            return <FormMarketoIframe {...block} />;
        default:
            return null;
    }
};

export default Form;
